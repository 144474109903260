import {BaseService, Command, config, loadApi, websocket} from "@systempiloten/react-lib";
import "@systempiloten/react-lib/dist/styles.css";
import "material-symbols/outlined.css";
import ReactDOM from "react-dom/client";
import React from "react";
import "./index.css";
import "./i18n";

window.addEventListener("load", () => {
    if ("serviceWorker" in navigator) {
        navigator.serviceWorker.register("/service-worker.js");
    }
});

const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement
);
(async () => {
    await fetch("/configuration.json")
        .then((res) => res.json())
        .then((data) => config.load(data))
        .catch((e) => null);
    BaseService.credentials.get("default").baseUrl = config.get("backend");
    await loadApi().catch(() => void 0);
    try {
        websocket.connect();
        websocket.cmd(Command.SyncProgressState,{})
    } catch (e) {

    }
    const {default: App} = await import("./App");
    root.render(<App/>);
})();
